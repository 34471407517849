<template>
    <div>
        <CCard accent-color="primary">
            <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <BlockColumn v-if="rol == 'logisticAdmin'">
        <BlockItem :size="'1-2'">

        <BlockInfoSelect
            :title="$t('company_code') + ' ' + '*'"
            v-model="selectedCompanyCode"
            :values="companyCodes"
            @chnage-value="changeCompanyCodes"
            :disabled="false"
          />
        </BlockItem>
      </BlockColumn>
      <BlockColumn v-if="rol == 'logisticAdmin'">
        <BlockItem :size="'1-2'">

        
          <BlockInfo
            :title="$t('search_carrier')"
            v-model="carrierSearch.name"
            :disabled="false"
          />
          </BlockItem>
          <BlockItem :size="'1-2'">

          <CButton
              :color="'success'"
              style="margin-bottom: 1rem;"
              size="lg"
              class="cardDriver-button"
              @click="searchCarrier()"
              >{{$t('search_carrier')}}</CButton
            >
        </BlockItem>
      </BlockColumn>
      <BlockColumn v-if="rol == 'logisticAdmin' && carrierList.length > 0">
          
          <BlockItem :size="'1-2'">
            <BlockInfoSelect
              :title="$t('carrier') + ':'"
              :selected="selectedCarrier"
              :values="carrierList"
              @change-value="changeCarrier"
            />
          </BlockItem>
        </BlockColumn>
      <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          :items-per-page-select="{label: $t('items_page')}"
          :loading="loading"
          :tableFilter='{ placeholder: placeholder_table }'
          pagination
        >
         
        <template #no-items-view>
            <div class="d-flex align-items-center justify-content-center my-3">
                <span class="mr-2" style="font-size: 30px; font-weight: 600; ">{{ $t('no_items') }}</span>
                <CIcon name="cil-ban" class="text-danger" style="width: 35px; height: 35px;" />
              </div>
          </template>
        </CDataTable>
        </CCard>
    </div>
</template>
<script>
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
import BlockInfo from "./BlockInfo.vue"
export default {
    name: "VehicleList",
    props: {
        tableItems: Array,
        totalPages: Number,
        companyCodes: Array,
        carrierList: Array
    },
    components: {
        BlockColumn,
        BlockItem,
        BlockInfoSelect,
        BlockInfo
    },
    watch: {
        carrierList(newVal) {
    console.log('Carrier list updated:', newVal);
    this.selectedCarrier = null;
    }
    },
    data() {
        return {
            tableTitle: this.$i18n.t("carrier_vehicles"),
            loading: false,
            tableFields: [
            { key: "licensePlateNumber", label: this.$t('licensePlateNumber') },
            { key: "vehicleType", label: this.$t('vehicleType') },
            { key: "salesOrganization", label: this.$t('salesOrganization') },
            { key: "maintenancePlant", label: this.$t('maintenancePlant') },
            { key: "loadVolume", label: this.$t('loadVolume') },
               
            ],

            placeholder_table: this.$i18n.i18next.t('type_string') + ' ↵',
            tableFilter: '',
            selectedCarrier: null,
            selectedCompanyCode: null,
            carrierSearch: {
                id: "",
                name: ""
            },
            carrierSource: "",
            rol: ""
        }
    },
    mounted() {
        let carrier = JSON.parse(localStorage.getItem("carrierInfo"));
        var userRol = JSON.parse(localStorage.getItem("userRol"));

        this.selectedCarrier = carrier ? carrier.id : null;
        this.carrierSource = carrier ? carrier.source : null;
        this.rol = userRol;
        this.loadData();
    },
    methods: {
        loadData() {
            
            
            this.$emit('change-loading', true)
            
            this.$emit('vehicle-list', this.getFilterData())
        },
        searchCarrier() {
            let country = JSON.parse(localStorage.getItem("userCountry"))
            console.log(this.selectedCompanyCode, 'this.selectedCompanyCode')
            
            let data = {
                companyCode: this.selectedCompanyCode,
                country: country,
                carrier: this.carrierSearch
            }
            this.$emit('carrier-selector', data)
        },
        handlePagination( itemsPerPage ) {
            this.perPage = itemsPerPage;
            this.$emit("vehicle-list", this.getFilterData());
        },
        getFilterData() {
            let country = JSON.parse(localStorage.getItem("userCountry"))
            if (this.rol == 'logisticAdmin') {
                this.carrierSource = this.carrierList.find(e => e._key == this.selectedCarrier).source
            }
            var filter = {
                carrierId: this.selectedCarrier,
                country: country
            }
            console.log(filter, 'filter')
            return filter;
        },
        updateFilter(value) {
            this.tableFilter = value;
            this.$emit("vehicle-list", this.getFilterData());
        },
        changeCarrier(carrier) {
            console.log(carrier)
            this.selectedCarrier = carrier;
            this.$emit("vehicle-list", this.getFilterData());
        },
        changeCompanyCodes(companyCode) {
            this.selectedCompanyCode = companyCode;
            this.selectedCarrier = null;
            this.carrierList = []
        },
       
    }
}
</script>